import { translate } from '@/core/localization/translate';
export const {
  selectedProductTitle,
  suggestedProductTitle,
  promotedProductTitle,
  recentlyTitle,
  ariaLabelPrevious,
  ariaLabelNext
} = translate({
  "selectedProductTitle": "Notre s\xE9lection maison",
  "suggestedProductTitle": "Nos top ventes",
  "promotedProductTitle": "Demandez les promos !",
  "recentlyTitle": "Produits vus r\xE9cemment",
  "ariaLabelPrevious": "Pr\xE9c\xE9dent",
  "ariaLabelNext": "Suivant"
});